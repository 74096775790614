import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

/**
 * Turnstile - Component managing Turnstile component which is Cloudflare's captcha system
 * @param {Object} params
 * @return {React.JSXElement}
 */
function Turnstile() {
  const [captchaFatal, setCaptchaFatal] = useState(false);
  const [initializedCaptcha, setInitializedCaptcha] = useState(false);
  const [state, dispatch] = useContext(AppContext);
  const { t } = useTranslation();

  /**
   * Initializing Turnstile component to inject site key and get component ID
   * @return {void}
   */
  const initTurnstile = () => {
    if (process.env.REACT_APP_TURNSTILE_SITE_KEY) {
      const id = window.turnstile?.render('#captcha-container', {
        'sitekey': process.env.REACT_APP_TURNSTILE_SITE_KEY,
        'theme': 'light',
        'callback': (value) => {
          dispatch({ type: 'SET_CAPTCHA', payload: value });
        },
        'error-callback': (error) => {
          dispatch({ type: 'CAPTCHA_ERROR', payload: error });
        },
      });

      dispatch({ type: 'INIT_CAPTCHA', payload: id });
      setInitializedCaptcha(true);
    } else {
      handleCaptchaFatalError('Could not load Turnstile script because Site Key is missing');
    }
  };


  /**
   * Handle Turnstile fatal error (making the captcha not showing),
   * it may be because the turnstile script was not correctly loaded or a fatal error upon executing the captcha challenge
   * @param {string} error the error message.
   * @return {void}
   */
  const handleCaptchaFatalError = (error) => {
    console.error('Could not load captcha, detected fatal error : ', error);
    dispatch({ type: 'REMOVE_CAPTCHA' });
    setCaptchaFatal(true);
  };

  useEffect(() => {
    initTurnstile();
    return () => {
      dispatch({ type: 'REMOVE_CAPTCHA' });
    };
  }, []);

  // Managing captcha error if it occur or if script was not started
  useEffect(() => {
    if (initializedCaptcha) {
      if (!window.turnstile) {
        handleCaptchaFatalError('Turnstile script could not be started, it may have been blocked by client');
      } else if (state.captcha.id && state.captcha.error) {
        // This is the simpliest way to check if turnstile error made the component to not show
        if (document.getElementById(state.captcha.id).style.display === 'none') {
          handleCaptchaFatalError(state.captcha.error);
        } else {
          console.error('Detected non-fatal captcha error : ', state.captcha.error);
        }
      }
    }
  }, [state.captcha.id, state.captcha.error, initializedCaptcha]);

  return captchaFatal ? <Typography.Text type="danger">{t('COULD_NOT_LOAD_CAPTCHA')}</Typography.Text> : <div id={'captcha-container'}></div>;
}

export default Turnstile;
