/** @jsxImportSource @emotion/react */
import { Button, Card, Flex, Select, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { breakpoints, sizes } from '../../globalVar/theme';
import useChangeLanguage from '../../hooks/useChangeLanguage';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useWindowSize } from '../../hooks/useWindowSize';
import mapI18N from '../../i18n/i18nAntD';
import { countryAlphaOrder } from '../../utils/countryAlphaOrder';
import CountriesSelector from './CountriesSelector/CountriesSelector';

const { Text } = Typography;

const inputStyle = {
  'width': '100%',
  [breakpoints.sm]: {
    maxWidth: '400px',
  },
};

/**
 * Settings
 * @return {string}
 */
function Settings() {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  useDocumentTitle(t('URL_SETTINGS'));

  const handleChangeLanguage = useChangeLanguage();
  const defaultValue = mapI18N.find((lang) => lang.keys.includes(i18n.languages[0]))?.name;
  const parametersDirection = width > sizes.md ? 'horizontal' : 'vertical';

  return (
    <Flex vertical gap={20}>
      <Card title={t('INTERNAL_SETTINGS')}>
        <Space direction='vertical' style={{ width: '100%' }}>
          <Text strong>{t('LANG')}</Text>
          <Select
            css={inputStyle}
            showSearch
            defaultValue={defaultValue}
            onSelect={handleChangeLanguage}
            options={countryAlphaOrder(mapI18N).map((lang) =>({
              value: lang.name,
              label: lang.name,
            })
            )}
          />
        </Space>
        <CountriesSelector inputStyle={inputStyle} />
      </Card>
      <Card title={t('PRO_SETTINGS')}>
        <Space size="middle" direction={parametersDirection}>
          <Link to='/login/forgot-password'>
            <Button>
              {t('RESET_PASSWORD_PRO')}
            </Button>
          </Link>
          <Link to='/login/configure-password'>
            <Button>
              {t('SET_PRO_PASSWORD')}
            </Button>
          </Link>
        </Space>
      </Card>
    </Flex>
  );
}

export default Settings;
